@use './styles/_mixins.scss' as *;
.tabSlider {
  &_banner {
    position: relative;
    color: var(--color-white);
    top: rem(-70);
    margin-bottom: rem(-70);
    @include breakpoint(large) {
      top: rem(-127);
      margin-bottom: rem(-127);
    }

    .tabSlider_nav_glider {
      display: none;
    }
  }

  &_sticky {
    .tabSlider_nav_wrap {
      background: transparent;
      box-shadow: none;
    }
    .tabSlider_nav_glider {
      opacity: 0;
    }
  }

  // &_sticked {
  //   .tabSlider_nav_wrap {
  //     background: var(--color-secondary);
  //     box-shadow:
  //       0 8px 16px 0 rgba(0, 0, 0, 0.2),
  //       0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //   }
  //   .tabSlider_nav_glider {
  //     opacity: 1;
  //   }
  //   .tabSlider_nav_item_cta {
  //     opacity: 1;
  //     visibility: visible;
  //   }
  // }

  &_sticky:not(.tabSlider_sticked) {
    @include breakpoint(large) {
      .tabSlider_nav_item {
        transition: transform 0.2s ease-in-out;
        &:hover {
          transform: translateY(-5px);
        }
      }
    }
  }

  &_small {
    @media (min-width: 500px) and (max-width: 768px) {
      .tabSlider_nav_wrap {
        display: inline-flex;
        margin: auto;
        margin-bottom: 1.5rem;
        padding: 0.375rem;
        border-radius: 99px;
      }
    }
  }

  &_nav {
    position: relative;
    display: flex;
    font-size: rem(13);
    font-weight: var(--font-large);
    z-index: 2;
    margin: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    @include breakpoint(medium) {
      // display: inline-flex;
      width: 100%;
      font-size: rem(15);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &_wrap {
      position: relative;
      overflow: hidden;
      margin-left: rem(-8);
      // margin-right: rem(-16);
      margin-right: rem(-8);
      margin-bottom: rem(24);
      padding: rem(4);
      background: var(--color-primary);
      // border-top-left-radius: 99px;
      // border-bottom-left-radius: 99px;
      border-radius: 99px;
      z-index: 2;
      box-shadow:
        0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      transition:
        background 0.2s ease,
        box-shadow 0.2s ease;

      @include breakpoint(medium) {
        display: inline-flex;
        margin: auto;
        // margin-bottom: rem(24);
        padding: rem(6);
        border-radius: 99px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &_item {
      position: relative;
      padding: rem(12) rem(16);
      border-radius: 99px;
      cursor: pointer;
      z-index: 2;
      flex: 0 0 auto;
      text-align: center;

      @include breakpoint(small) {
        &_active {
          background-color: var(--color-dark);
        }
      }

      @include breakpoint(medium) {
        min-width: unset;
        padding: rem(16);
        flex: 1;
      }

      &_cta {
        margin-left: 40px;
        opacity: 0;
        visibility: hidden;
        background: var(--color-primary);
        transition:
          visibility 0.2s ease-out,
          opacity 0.2s ease-out;
      }
    }

    &_glider {
      display: none;
      @include breakpoint(medium) {
        position: absolute;
        display: flex;
        // background-color: var(--color-gray3);
        background-color: var(--color-dark);
        z-index: 1;
        border-radius: 99px;
        transition:
          width 0.3s ease-out,
          transform 0.3s ease-out,
          opacity 0.2s ease-out;
        height: 50px;
        top: 6px;
      }

      @include breakpoint(large) {
        width: calc(100% / 3);
      }
      @include breakpoint(huge) {
        height: 50px;
      }
    }
  }
}
