@use './styles/_mixins.scss' as *;
.fillingText {
  padding-top: rem(32);
  padding-bottom: rem(32);
  color: var(--color-white);
  overflow: hidden;

  &.padding {
    @include breakpoint(medium) {
      padding-bottom: rem(100);
    }
    @include breakpoint(huge) {
      padding-bottom: rem(120);
    }
  }

  &.hpBottomText {
    @include breakpoint(medium) {
      padding-top: rem(100);
    }
  }

  &_dark {
    color: var(--color-secondary);
  }

  &_text {
    font-size: clamp(1.25rem, 1.0833rem + 0.3472vw, 1.5rem);
    font-weight: var(--font-regular);
    @include breakpoint(small) {
      font-size: 15px;
    }

    .fillingText_small & {
      font-size: rem(15);
      @include breakpoint(large) {
        font-size: clamp(1.25rem, 0.75rem + 0.625vw, 1.5rem);
      }
    }

    span {
      position: relative;
      opacity: 0.1;
      z-index: 2;
    }

    a {
      font-weight: var(--font-medium);
      text-decoration: underline;
    }
  }

  &_subheading {
    position: relative;
    margin-bottom: rem(24);
    color: var(--color-primary);
    font-size: rem(16);
    font-weight: var(--font-medium);
    letter-spacing: -0.6px;
    z-index: 2;

    @include breakpoint(large) {
      margin-bottom: rem(24);
      font-size: rem(24);
    }

    strong,
    b {
      font-weight: var(--font-demi);
    }
  }

  &_content {
    @include breakpoint(large) {
      max-width: 1560px;
      margin: auto;

      h3 {
        font-size: rem(44);
      }
    }
  }
}
